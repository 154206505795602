import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'hooks'
import { Field, useField, useFieldState } from 'formular'

import { useAddressByCoords, useCoordsByAddress } from 'models/mapLayer'


const cache = {}

const useGeoCoder = (
  { addressField, placemarksField }: { addressField: Field<string>, placemarksField: Field<any> }, skip?: boolean
) => {
  const emptyField = useField<any>()

  const { value: placemarksValue, isChanged: isPlacemarksFieldChanged } = useFieldState(placemarksField || emptyField)
  const { value: addressValue, isChanged: isAddressFieldChanged, isValidating, isValidated } = useFieldState<string>(addressField || emptyField)

  const [ isPlacemarkGeocoded, setIsPlacemarkGeocoded ] = useState<boolean>(false)
  const [ isAddressGeocoded, setIsAddressGeocoded ] = useState<boolean>(false)

  const geometry = placemarksValue?.[0]?.geometry

  const fieldsRef = useRef({
    address: addressValue,
    geometry,
  })

  fieldsRef.current = {
    address: addressValue,
    geometry,
  }

  const addressValueDebounced = useDebounce(addressValue, 750)

  const { address, isAddressFetching } = useAddressByCoords(geometry, Boolean(cache[JSON.stringify(geometry)]) || skip || !isPlacemarksFieldChanged)
  const { coords, isCoordsFetching } = useCoordsByAddress(addressValueDebounced, Boolean(cache[addressValue]) || skip || !isAddressFieldChanged)

  useEffect(() => {
    if (address && addressField && !skip && isPlacemarksFieldChanged) {
      cache[address] = fieldsRef.current.geometry
      addressField.set(address)
      addressField.state.isChanged = false
      setIsAddressGeocoded(true)
    }
  }, [ address, addressField, isPlacemarksFieldChanged, skip ])

  useEffect(() => {
    if (coords && placemarksField && !skip && isAddressFieldChanged) {
      cache[JSON.stringify(coords)] = fieldsRef.current.address

      const newState = placemarksField.state.value.map((item, index) => {
        if (!index) {
          return {
            ...item,
            geometry: coords,
          }
        }

        return item
      })

      placemarksField.set(newState)
      placemarksField.state.isChanged = false

      setIsPlacemarkGeocoded(true)
    }
  }, [ coords, isAddressFieldChanged, placemarksField, skip ])

  return { isPlacemarkGeocoded, isAddressGeocoded, isAddressFetching, isCoordsFetching }
}


export default useGeoCoder
