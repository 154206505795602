import { useEffect, useMemo, useState } from 'react'
import { useAccesses } from 'models/user'
import links, { routeData } from 'links'

import useChannelsList from './useChannelsList'


const channels: Notifications.Channel[] = [
  {
    id: 1,
    title: 'Городские проблемы',
    name: 'appeals',
    tag: routeData[links.appeals.root]?.tag,
  },
  {
    id: 8,
    title: 'Комментарии',
    mergedId: 1,
    tag: routeData[links.appeals.root]?.tag,
  },
  {
    id: 2,
    title: 'Комментарии',
    mergedId: 1,
    tag: routeData[links.appeals.root]?.tag,
  },
  {
    id: 5,
    title: 'Опер. оповещения',
    name: 'alerts',
    tag: routeData[links.alerts.root]?.tag,
  },
  {
    id: 7,
    title: 'Зем. работы',
    name: 'excavation',
    tag: routeData[links.excavation.root]?.tag,
  },
  {
    id: 17,
    title: 'Уборка',
    name: 'cleaning',
    tag: routeData[links.cleaning]?.tag,
  },
  {
    id: 20,
    title: 'Достопримечательности',
    name: 'culture',
    tag: routeData[links.culture.root]?.tag,
  },
  {
    id: 21,
    title: 'Опросы',
    name: 'votings',
    tag: routeData[links.votings.root]?.tag,
  },
  {
    id: 19,
    title: 'Новости',
    name: 'news',
    tag: routeData[links.news.root]?.tag,
  },
  {
    id: 22,
    title: 'Проекты развития города',
    name: 'cityProjects-gv',
    tag: routeData[links.projects.root]?.tag,
  },
  {
    id: 23,
    title: 'Инициативные проекты',
    name: 'cityProjects-ib',
    tag: routeData[links.projects.root]?.tag,
  },
  {
    id: 24,
    title: 'Планы по благоустройству',
    name: 'cityProjects-sz',
    tag: routeData[links.projects.root]?.tag,
  },
  {
    id: 26,
    title: 'Поручения',
    name: 'requests',
    tag: routeData[links.admin.requests.root]?.tag,
  },
  {
    id: 27,
    title: 'Аренда',
    name: 'spacesForRent',
    tag: routeData[links.rent.root]?.tag,
  },
  {
    id: 28,
    title: 'Товары и услуги',
    name: 'advertising',
    tag: routeData[links.goodsAndServices.root]?.tag,
  },
  {
    id: 3,
    title: 'Электропитание',
    name: 'electric',
    tag: routeData[links.admin.electric.root]?.tag,
  },
  // {
  //   id: 24,
  //   title: 'Проекты строительства',
  //   name: 'cityProjects-bld',
  // },
]

const useChannels = () => {
  const { accesses } = useAccesses()
  const [ isAdmin, setAdmin ] = useState(Boolean(accesses.length))
  const { channelsList } = useChannelsList({
    skip: isAdmin,
  })

  useEffect(() => {
    if (accesses.length) {
      setAdmin(true)
    }
  }, [ accesses ])

  const notifyTags = useMemo(() => {
    if (accesses?.length) {
      return accesses
        // .filter((access) => access?.notifySettings?.notify)
        .map(({ module }) => module.tag)
    }

    return []
  }, [ accesses ])

  const adminChannels = useMemo(() => {
    return channels.filter(({ tag }) => !tag || notifyTags.includes(tag as UserModel.ModuleTag))
  }, [ notifyTags ])

  const userChannels = useMemo(() => {
    return channelsList.map(({ value, label }) => ({
      id: value,
      title: label,
    }))
  }, [ channelsList ])

  return {
    notifyTags,
    channels: isAdmin ? adminChannels : userChannels,
    isAdminLinks: isAdmin,
  }
}


export default useChannels
