import { useCallback, useEffect, useMemo, useRef } from 'react'
import links, { getLinkWithParams } from 'links'
import { useMutation, useQuery } from 'hooks'
import dayjs from 'date'

import { useChannels } from 'models/notifications'
import { useUser } from 'models/user'
import { useDevice } from 'device'


const limit = 10000

const getAdminNotificationLink = ({ type, itemId, subItemId }) => {
  const channelLinks = {
    7: `${links.admin.excavation.item}?id=${itemId}`,
    5: getLinkWithParams(links.admin.alerts.item, { itemId }),
    17: getLinkWithParams(links.admin.cleaning.editItem, { itemId }) + '?comments',
    20: getLinkWithParams(links.admin.culture.editItem, { itemId }),
    2: getLinkWithParams(links.admin.appeals.item, { itemId }) + '?tab=comments',
    8: getLinkWithParams(links.admin.appeals.item, { itemId }) + '?tab=comments',
    1: getLinkWithParams(links.admin.appeals.item, { itemId }) + '?tab=description',
    19: `/manager/news/${itemId}/edit`,
    21: `/manager/votes/${itemId}/edit`,
    22: `/manager/comments/${itemId}/edit`,
    23: `/manager/comments/${itemId}/edit`,
    24: `/manager/comments/${itemId}/edit`,
    28: `/manager`,
    26: (!subItemId || subItemId === itemId)
      ? getLinkWithParams(links.admin.requests.project, { itemId })
      : getLinkWithParams(links.admin.requests.task, { itemId, subItemId }),
    27: getLinkWithParams(links.admin.rent.edit, { itemId }),
  }

  return channelLinks[type] || ''
}

const getUserNotificationLink = ({ type, itemId, subItemId }) => {
  const channelLinks = {
    7: `${links.excavation.root}?id=${itemId}`,
    5: getLinkWithParams(links.alerts.item, { itemId }),
    17: getLinkWithParams(links.cleaning, { itemId }) + '?comments',
    20: getLinkWithParams(links.culture.item, { itemId }),
    2: getLinkWithParams(links.appeals.item.root, { itemId }) + '?tab=comments',
    8: getLinkWithParams(links.appeals.item.root, { itemId }) + '?tab=comments',
    1: getLinkWithParams(links.appeals.item.root, { itemId }) + '?tab=description',
    // 19: `/manager/news/${itemId}/edit`,
    // 21: `/manager/votes/${itemId}/edit`,
    // 22: `/manager/comments/${itemId}/edit`,
    // 23: `/manager/comments/${itemId}/edit`,
    // 24: `/manager/comments/${itemId}/edit`,
    // 28: `/manager`,
    // 26: (!subItemId || subItemId === itemId)
    //   ? getLinkWithParams(links.admin.requests.project, { itemId })
    //   : getLinkWithParams(links.admin.requests.task, { itemId, subItemId }),
    27: getLinkWithParams(links.rent.root, { itemId }),
  }

  return channelLinks[type] || ''
}

const useEventsNotifications = () => {
  const { channels, isAdminLinks } = useChannels()
  const { isMobile } = useDevice()

  const channelsById = useMemo(() => {
    return channels.reduce((result, { id, mergedId }) => {
      result[id] = mergedId || id

      return result
    }, {})
  }, [ channels ])

  const channelTitlesById = useMemo(() => {
    return channels.reduce((result, { id, title }) => {
      result[id] = title

      return result
    }, {})
  }, [ channels ])

  const cannelsRef = useRef({ channelsById, channelTitlesById })
  cannelsRef.current = { channelsById, channelTitlesById }

  const [ submitRead, { isSubmitting } ] = useMutation({
    url: '/ajax.php?action=consumeEvent&typeKey=Int&eventId=Int&unread=0|1',
  })

  const submitReadRef = useRef(submitRead)

  const queryOptions = useMemo(() => ({
    url: `/ajax.php?action=getEvents&json=` + JSON.stringify({
    // url: `/ajax.php?action=getEvents&limitAmount=${limit}&pageSize=${limit}&json=` + JSON.stringify({
      k: channels.map(({ id }) => id),
    }),
    modifyResult: ({ unread, all, uI, uM }) => {
      return {
        count: Number(unread),
        authData: {
          userId: uI,
          userMode: uM,
        },
        notifications: all?.map(({ type, created, comment, taskId, json, eventId, readed }) => {
          const title = `${cannelsRef.current.channelTitlesById[type]}`
          const adminLink = json?.url || getAdminNotificationLink({ type, itemId: taskId, subItemId: json?.directiveId })
          const userLink = getUserNotificationLink({ type, itemId: taskId, subItemId: json?.directiveId })

          const channelId = cannelsRef.current.channelsById[type]

          return ({
            channelId,
            readed,
            date: dayjs(created).format('DD MMMM в HH:mm'),
            title,
            text: comment,
            to: isAdminLinks && !isMobile ? adminLink : userLink,
            submitRead: () => submitReadRef.current({
              url: '/ajax.php?action=consumeEvent&typeKey=' + channelId + '&unread=0' + '&eventId=' + eventId,
            }),
          })
        }) || [],
      }
    },
    skip: !channels?.length,
  }), [ channels, isAdminLinks ])

  const {
    data: eventsData,
    isFetching: isEventsDataFetching,
    fetch: fetchEventsData,
  } = useQuery(queryOptions)
  const { user, handleLogout } = useUser()

  useEffect(() => {
    if (eventsData?.authData) {
      const isUnauthorized = [ 0, -1 ].includes(Number(eventsData?.authData?.userId))

      if (isUnauthorized) {
        console.error('Unauthorized', eventsData?.authData)
        // if (handleLogout) {
        //   handleLogout()
        // }
        // else {
        //   window.location.href = links.logout
        // }
      }
    }
  }, [ user, eventsData, handleLogout ])

  const handleSubmit = useCallback(async (channelId) => {
    const channelsList = channels.filter(({ id, mergedId }) => (
      !channelId
      || id === channelId
      || mergedId === channelId
    ))

    if (channelsList.length) {
      const [ { data, cacheStorage } ] = await Promise.all(
        channelsList.map(({ id }) => (
          submitRead({
            url: '/ajax.php?action=consumeEvent&typeKey=' + id + '&unread=0',
          })
        ))
      )

      cacheStorage.reset(/\/ajax\.php\?action=getEvents.*/)
    }
  }, [ submitRead, channels ])

  const notificationLinks = useMemo(() => {
    if (!isEventsDataFetching && eventsData?.notifications) {
      return eventsData?.notifications.map(({ to }) => to)
    }

    return null
  }, [ eventsData, isEventsDataFetching ])

  return {
    eventsData,
    notificationLinks,
    isEventsDataFetching,
    submitReadEvents: handleSubmit,
    fetchEventsData,
  }
}


export default useEventsNotifications
