import config from 'config'
import { useMutation } from 'hooks'
import { useEffect, useMemo, useState } from 'react'


const useCoordsByAddress = (address: string, skip: boolean) => {
  const { cityName } = config

  const [ data, setData ] = useState<[ number, number ]>()

  const [ submit, { isSubmitting } ] = useMutation<{ lat: string, lng: string }>({
    url: '/mob_ajax.php?action=geosearch',
  })

  const formattedAddress = useMemo(() => address.trim(), [ address ])

  useEffect(() => {
    if (skip || !formattedAddress || !cityName) {
      return
    }

    const body = {
      address: `${cityName} ${formattedAddress}`,
    }

    submit(body).then(({ data }) => setData([ Number(data.lat), Number(data.lng) ]))
  }, [ formattedAddress, cityName, skip, submit ])

  return {
    coords: data,
    isCoordsFetching: isSubmitting,
  }
}


export default useCoordsByAddress
